
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AJLoader from "@/components/AjaxLoader.vue";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { UserListDto } from "@/shared/service-proxies/service-proxies";
import { UsersFilter } from "@/models";
import useUsers from "@/composables/useUsers";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";

export default defineComponent({
  name: "Users",

  components: {
    Datatable,
    AJLoader,
  },

  setup() {
    const { users, getUsers, deleteUser } = useUsers();

    let loading = ref<boolean>(true);

    const breadcrumbs = {
      title: "Users",
      breadcrumbs: ["Manage users and permissions"],
    };

    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Username",
        key: "username",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Surname",
        key: "surname",
        sortable: true,
      },
      {
        name: "Roles",
        key: "roles",
      },
      {
        name: "Email address",
        key: "emailAddress",
        sortable: true,
      },
      {
        name: "Email confirm",
        key: "emailConfirm",
      },
      {
        name: "Active",
        key: "active",
      },
      {
        name: "Creation time",
        key: "creationTime",
      },
    ]);

    const tableData = ref<Array<UserListDto>>([]);

    const initialData = ref<Array<UserListDto>>([]);

    const defaultFilter = {
      filter: "",
      onlyLockedUsers: false,
      sorting: undefined,
      maxResultCount: 1000,
      skipCount: 0,
    } as unknown as UsersFilter;

    onMounted(async () => {
      // setCurrentPageBreadcrumbs("Users", ["Manage users and permissions."]);
      await getData();
    });

    const getData = async () => {
      loading.value = true;
      await getUsers(defaultFilter);

      tableData.value = users.value;

      initialData.value.splice(0, tableData.value.length, ...tableData.value);

      loading.value = false;
    };

    const loginAsUser = (id: number) => {
      console.log(id);
    };

    const deleteHandler = async (id: number) => {
      // console.log(id);
      const res = await DeleteItemService.delete(
        id as unknown as string,
        deleteUser
      );
      if (res) {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    return {
      moment,
      loading,
      tableData,
      tableHeader,
      loginAsUser,
      deleteHandler,
      breadcrumbs,
      permissions,
      defaultFilter,
      getData,
    };
  },
});
