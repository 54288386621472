import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { UsersFilter } from "@/models";
import {
  CreateOrUpdateUserInput,
  UserListDto,
  UserServiceProxy,
} from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableModel {
  users: Ref<Array<UserListDto>>;
  userForEdit: Ref<any>;
  getUsers: (filters: UsersFilter) => void;
  getUserForEdit: (id: number) => void;
  updateOrCreateUser: (inputData: CreateOrUpdateUserInput) => void;
  deleteUser: (id: string) => Promise<boolean>;
}

const UsableModel = (): UsableModel => {
  const store = useStore();
  const client = new UserServiceProxy(APP_BASE_URL, axiosClient);

  const users: Ref<Array<UserListDto>> = ref([]);
  const userForEdit: Ref<any> = ref(null as unknown as any);

  const getUsers = async (filters: UsersFilter) => {
    const {
      filter,
      permissions,
      role,
      onlyLockedUsers,
      sorting,
      maxResultCount,
      skipCount,
      cancelToken,
    } = filters;

    await client
      .getUsers(
        filter,
        permissions,
        role,
        onlyLockedUsers,
        sorting,
        maxResultCount,
        skipCount,
        cancelToken
      )
      .then((data) => {
        const { items } = data;

        users.value = items as unknown as Array<UserListDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getUserForEdit = async (params: number) => {
    const response = await client.getUserForEdit(params);

    if (!response) {
      console.log(response);
      return;
    }

    userForEdit.value = response;
  };

  const updateOrCreateUser = async (inputData: CreateOrUpdateUserInput) => {
    await client
      .createOrUpdateUser(inputData)
      .catch((error) => store.commit(Mutations.SET_ERROR, error));
  };

  const deleteUser = async (id: string): Promise<boolean> => {
    const resp = await client
      .deleteUser(id as unknown as number)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
    // try {
    //   await client.deleteUser(id as unknown as number);
    //   return null;
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return {
    userForEdit,
    users,
    getUsers,
    getUserForEdit,
    updateOrCreateUser,
    deleteUser,
  };
};

export default UsableModel;
